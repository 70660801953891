
import { defineComponent, onMounted, ref, reactive, computed } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { getEncounter } from "@/api/encounter.api";
import { Service } from "@/modules/encounter/encounter.model";
import {
  addClaim,
  deleteClaim,
  searchClaims,
  updateClaim,
} from "@/api/claim.api";
import { getPatient } from "@/api/patient.api";
import EncounterComponent from "@/modules/encounter/EncounterComponent.vue";
import { useRouter } from "vue-router";
import { getDictionary } from "@/api/dictionaries.api";
import Multiselect from "@vueform/multiselect";
import { Patient } from "@/modules/patient/patient.model";
import { Claim } from "@/modules/claims/claim.model";
import useVuelidate from "@vuelidate/core";
import { helpers,minLength, required } from "@vuelidate/validators";
import ClaimComponent from "@/modules/claims/ClaimComponent.vue";
import { getPanel } from "@/api/panel.api";

export interface Data {
  encounter: {
    services: Service[];
    id: string;
    patientId: string;
  };
  createMode: boolean;
  createModeEnabled: boolean;
  claims: [];
  panel: unknown;
  patient?: Patient;
  newClaim?: Claim;
  serviceList: [];
  dict: {
    typeOfFacility: [];
    typeOfCare: [];
    frequency: [];
    admissionType: [];
    admissionSource: [];
    dischargeStatus: [];
  };
}

export default defineComponent({
  name: "EncountersPage",
  props: ["encounterId"],
  components: { EncounterComponent, ClaimComponent, Multiselect },
  setup(props) {
    const router = useRouter();
    let organizationId = ref<string | null>("");
    let data = reactive<Data>({
      encounter: { services: [], id: "", patientId: "" },
      panel: { facilities: [], patients: [], providers: [] },
      claims: [],
      patient: undefined,
      newClaim: undefined,
      createMode: false,
      createModeEnabled: true,
      serviceList: [],
      dict: {
        typeOfFacility: [],
        typeOfCare: [],
        frequency: [],
        admissionType: [],
        admissionSource: [],
        dischargeStatus: [],
      },
    });

    onMounted(async () => {
      organizationId.value = getOrganization();
      setCurrentPageTitle("Encounter");
      data.panel = await getPanel();
      data.encounter = await getEncounter(props.encounterId);
      data.patient = await getPatient(data.encounter.patientId);
      await getAllClaims();

      await getDict();

      // data.newClaim = {
      //   id: "",
      //   claimId: "",
      //   typeOfBill: "",
      //   admissionType: "",
      //   admissionSource: "",
      //   dischargeStatus: "",
      //   documentControlNumber: "",
      //   remarksField: "",
      //   encounterId: data.encounter.id,
      //   billingProviderId: "",
      //   payerId: "",
      //   servicesId: [],
      // };
      // if (data.patient) {
      //   const patientPayer = data.patient.patientPayers[0];
      //   if (patientPayer) {
      //     data.newClaim.payerId = patientPayer.id;
      //   }
      // }
    });

    const claimRules = {
      newClaim: {
        admissionType: { required:helpers.withMessage("Required", required) },
        admissionSource: { required:helpers.withMessage("Required", required) },
        dischargeStatus: { required:helpers.withMessage("Required", required) },
        documentControlNumber: { required:helpers.withMessage("Required", required) },
        remarksField: { required:helpers.withMessage("Required", required) },
        encounterId: { required:helpers.withMessage("Required", required) },
        billingProviderId: { required:helpers.withMessage("Required", required) },
        payerId: { required:helpers.withMessage("Required", required) },
        typeOfFacility: { required:helpers.withMessage("Required", required) },
        typeOfCare: { required:helpers.withMessage("Required", required) },
        frequency: { required:helpers.withMessage("Required", required) },
      },
    };

    const v$ = useVuelidate(claimRules, data as never);

    async function getDict() {
      data.dict.typeOfFacility = await getDictionary(4, "");
      data.dict.typeOfCare = await getDictionary(5, "");
      data.dict.frequency = await getDictionary(6, "");
      data.dict.admissionType = await getDictionary(7, "");
      data.dict.admissionSource = await getDictionary(8, "");
      data.dict.dischargeStatus = await getDictionary(9, "");
    }

    const getAvailableServices = computed(() => {
      if (!data.patient || !data.newClaim) return [];



      const payer = data.patient.patientPayers.find((item) => {
        return item.id == data.newClaim?.payerId;
      });
      if (!payer) return [];
      if (payer.termDate != undefined && payer.effectiveDate != undefined ){
        const to = payer.termDate;
        const from = payer.effectiveDate;
        return data.encounter.services
        .filter((item) => {
          return (
            (item.serviceDate as any) < to &&
            (item.serviceDate as any) > from
          );
        });
      }
        
      return data.encounter.services;
    });

    const ifPayerEffective = computed(() => {
      if (!data.patient || !data.newClaim) return [];

      const payer = data.patient.patientPayers.find((item) => {
        return item.id == data.newClaim?.payerId;
      });
      if (!payer) return false;
      if (payer.endDate != undefined && payer.effectiveDate != undefined ){
        const to = payer.endDate;
        const from = payer.effectiveDate;
        return data.encounter.services
        .some((item) => {
          return (
            (item.serviceDate as any) < to &&
            (item.serviceDate as any) > from
          );
        });
      }
      
      return true;
    });

    async function claimDeleted() {
      await getAllClaims();
    }
    async function getAllClaims() {
      let request = {
        keyword: "",
        pageNumber: 0,
        pageSize: 100,
        orderBy: ["id"],
        encounterId: data.encounter.id,
      };

      const res = await searchClaims(request);
      data.claims = res.data;
    }

    async function updateClaimData(claim) {
      await updateClaim(claim);
    }

    async function deleteClaimData(claim) {
      await deleteClaim(claim);
      await getAllClaims();
    }

    async function createClaim() {
      const result = await v$.value.newClaim.$validate();
      if (result && data.newClaim) {
        data.newClaim.servicesId = data.serviceList;
        const res = await addClaim(data.newClaim);
        if(res){
          await getAllClaims();
          data.encounter = await getEncounter(props.encounterId);
          undoClaim();
        }        
      }
    }

    function selectClaim(claim) {
      router.push({ name: "waystarFieldsPage", params: { claimId: claim.id } });
    }

    function newClaim() {
      data.createMode = true;
    }

    function undoClaim() {
      // data.createMode = false;
      // data.serviceList = [];
      // data.newClaim = {
      //   id: "",
      //   claimId: "",
      //   typeOfBill: "",
      //   admissionType: "",
      //   admissionSource: "",
      //   dischargeStatus: "",
      //   documentControlNumber: "",
      //   remarksField: "",
      //   encounterId: data.encounter.id,
      //   billingProviderId: "",
      //   payerId: "",
      //   servicesId: [],
      // };

      // if (data.patient) {
      //   const patientPayer = data.patient.patientPayers[0];
      //   if (patientPayer) {
      //     data.newClaim.payerId = patientPayer.id;
      //   }
      // }
    }

    return {
      organizationId,
      claimDeleted,
      data,
      getAllClaims,
      updateClaimData,
      createClaim,
      deleteClaimData,
      getAvailableServices,
      ifPayerEffective,
      selectClaim,
      newClaim,
      undoClaim,
      v$,
    };
  },
});
